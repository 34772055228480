import React from "react"
import { graphql } from "gatsby"
// import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

import hiringStyles from "./hiring.module.css"
import logoImage_One from "../images/logos/Stodgy_Logo_FC_Outline.png"
import logoImage_Two from "../images/logos/Stodgy_Logo_FC_Black_Neg_Outline.png"
import logoImage_Three from "../images/logos/Stodgy_Logo_Outline.png"
import logoImage_Four from "../images/logos/Stodgy_Logo_FC_White_Outline.png"
import logoImage_Five from "../images/logos/Stodgy_Logo_FC_Black_Outline.png"

const HiringPage = ({ data }) => (
    <Layout>
        <SEO title="Logos" />
        <div className={hiringStyles.backgroundColor}></div>
        <div className={hiringStyles.constructionSection}>
            <h1>Stodgy Brewing Logos</h1>
            {/* <p>There's construction on Laporte Ave! The road is closed WEST of Stodgy. Please approach from the east.</p> */}
            <div className={hiringStyles.logoContainer}>
                <img alt="Stodgy Brewing Company Logo" src={logoImage_One}></img>
                <img alt="Stodgy Brewing Company Logo" src={logoImage_Two}></img>
                <img alt="Stodgy Brewing Company Logo" src={logoImage_Three}></img>
                <img alt="Stodgy Brewing Company Logo" src={logoImage_Four}></img>
                <img alt="Stodgy Brewing Company Logo" src={logoImage_Five}></img>
            </div>
        </div>
    </Layout>
)

export default HiringPage
